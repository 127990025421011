import React from "react";
import { Footer, Header, SEO } from "../components";
import { Box } from "@chakra-ui/react";
import {
	AboutUs,
	Industries,
	NavCluster,
	Partners,
	Projects,
	Services,
	Team,
} from "../components/main-page-parts";

const MainPage: React.FC = () => (
	<Box bg="background" overflowX="hidden">
		<SEO
			title={"LegioSoft"}
			description={
				"Globally-focused enterprise solutions provider. We leverage advanced cloud technologies to develop diverse, successful projects for our clients."
			}
			keywords={[
				"software development",
				"web development",
				".net core development",
				"node js development",
				"react development",
				"mobile development",
				"react native",
			]}
		/>
		<Header />
		<NavCluster />
		<AboutUs />
		<Team />
		<Industries />
		<Services />
		<Projects />
		<Partners />
		<Footer />
	</Box>
);

export default MainPage;
