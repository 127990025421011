import React from "react";
import { Box, Text, Heading, SimpleGrid, Container } from "@chakra-ui/react";
import { industries } from "../../constants";
import { colors } from "../../constants";
import { motion } from "framer-motion";
import MainBlue from "../../images/main_blue.png";
import MainPurple from "../../images/main_purple.png";

const MotionBox = motion(Box);

export const Industries: React.FC = () => (
	<Container maxW="container.xl" position="relative">
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 1 }}
			border={`2px solid ${colors.pointsBorder}`}
			bg="background"
			borderRadius="50px"
			mt="150px"
			py="120px"
			zIndex={10}
			position="inherit"
		>
			<Heading color="white" fontSize="40px" textAlign="center" textTransform="uppercase">
				Industries
			</Heading>
			<Text
				color="white"
				fontSize="20px"
				textAlign="center"
				my="30px"
				px={{ base: "5%", md: "25%" }}
			>
				As experienced web and mobile developers, we apply our knowledge of technology and
				product creation peculiarities to design outstanding applications over the different
				types industries.
			</Text>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="19px" mt="70px" px="10%">
				{industries.map(elem => (
					<Box
						key={elem.name}
						bg={colors.bulletPointBG}
						border={`1px solid ${colors.bulletPointBorder}`}
						boxSizing="border-box"
						boxShadow={`0px 17px 20px ${colors.bulletPointShadow}`}
						borderRadius="30px"
						p="30px 40px"
					>
						<Text
							color="primary"
							textShadow={`0px 0px 11px ${colors.primary}`}
							fontSize="20px"
							fontFamily="Poppins"
							fontWeight="600"
							lineHeight="25px"
						>
							{elem.name}
						</Text>
						{elem.items.map((item, index) => (
							<Text key={index} color="white" fontSize="16px" mt="25px">
								{item}
							</Text>
						))}
					</Box>
				))}
			</SimpleGrid>
		</MotionBox>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={MainBlue}
			position="absolute"
			top="0px"
			left="-350px"
			zIndex={1}
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={MainPurple}
			position="absolute"
			top="700px"
			right="-300px"
			zIndex={1}
		/>
	</Container>
);
