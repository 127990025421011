import React from "react";
import {Heading, Box, Text, Flex, Center, Container} from "@chakra-ui/react";
import {aboutUsPoints} from "../../constants";
import {colors} from "../../constants";
import {motion} from "framer-motion";

export const AboutUs: React.FC = () => (
	<Container maxW="container.xl" zIndex={10} position="relative">
		<Box mt="270px">
			<Heading color="white" fontSize="40px" textAlign="center" textTransform="uppercase">
                About us
			</Heading>
			<Text
				color="white"
				fontSize="20px"
				textAlign="center"
				px={{base: "0", md: "25%"}}
				my="40px"
			>
                As a globally-focused organization, we specialize in providing comprehensive enterprise solutions. From
                our establishment, we have successfully developed and continue to maintain a diverse portfolio of
                projects, leveraging advanced cloud-based technologies to meet our clients needs.
			</Text>
			<Flex mt="100px" flexDirection={{base: "column", md: "row"}}>
				{aboutUsPoints.map(elem => (
					<Box key={elem.title} flex={1} mt={{base: "30px", md: "0"}}>
						<Center h="50px" mb="35px">
							{elem.icon}
						</Center>
						<Text
							color="primary"
							textShadow={`0px 0px 11px ${colors.primary}`}
							fontSize="20px"
							fontFamily="Poppins"
							fontWeight="600"
							lineHeight="25px"
							textAlign="center"
						>
							{elem.title}
						</Text>
						<Text color="white" fontSize="16px" px="10px" textAlign="center" mt="20px">
							{elem.description}
						</Text>
					</Box>
				))}
			</Flex>
		</Box>
	</Container>
);
