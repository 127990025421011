import React from "react";
import { Heading, Text, Button, Box, Container } from "@chakra-ui/react";
import { LinksStrip } from "../LinksStrip";
import { Canvas } from "../Canvas";
import ReactResizeDetector from "react-resize-detector";
import { colors } from "../../constants";
import { navigate } from "gatsby";
import { motion } from "framer-motion";
import { isBrowser, isMobile } from "react-device-detect";
import WavesMain from "../../images/waves_home_main.png";
import MainBlue from "../../images/main_blue.png";
import MainRed from "../../images/main_red.png";
import MainPurple from "../../images/main_purple.png";
import MainGreen from "../../images/main_green.png";

const MotionBox = motion(Box);

export const NavCluster: React.FC = () => (
	<Box position="relative">
		<Container maxW="container.xl" zIndex={20} position="relative">
			<LinksStrip active="Home" />
			<Box zIndex={10} position="relative">
				<Heading as="h1" color="white" fontSize={{ base: "45px", md: "60px" }}>
					Web development
				</Heading>
				<Text color="white" fontSize="20px" my="30px" w={{ base: "100%", md: "50%" }}>
					Every business needs to have an online presence that represents
                    a massive opportunity for you.
				</Text>
				<Text color="white" fontSize="20px" w={{ base: "100%", md: "50%" }} mb="50px">
					Choosing the right web development company can make a world of difference.
				</Text>
				<Button
					variant="primary"
					textTransform="uppercase"
					onClick={() => navigate("/contacts")}
				>
					Contact Now
				</Button>
			</Box>

			<ReactResizeDetector handleWidth handleHeight refreshMode="debounce">
				{({ width, height }) => (
					<MotionBox
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						viewport={{ once: true }}
						transition={{ duration: 4 }}
						w={{ base: "100%", lg: "55vw" }}
						h="1000px"
						top={{ base: "0", lg: "-20vh" }}
						right={{ base: "0", lg: "-20vw" }}
						position="absolute"
						zIndex={0}
					>
						<Box>
							<Box
								transform={{ base: "none", lg: "rotate(-35deg)" }}
								position="absolute"
								zIndex={0}
							>
								<Canvas
									canvasWidth={width}
									canvasHeight={height}
									amp={500}
									max_lines={35}
									variation={0.001}
								/>
							</Box>
							<Box
								transform={{ base: "none", lg: "rotate(-65deg)" }}
								position="absolute"
								zIndex={0}
							>
								<Canvas canvasWidth={width} canvasHeight={height} />
							</Box>
						</Box>
					</MotionBox>
				)}
			</ReactResizeDetector>
		</Container>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={MainPurple}
			position="absolute"
			top="-200px"
			left="35vw"
			zIndex={0}
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={MainRed}
			zIndex={0}
			position="absolute"
			right="100px"
			top="-200px"
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={MainBlue}
			zIndex={0}
			position="absolute"
			right="250px"
			top="200px"
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			h="1000px"
			w="1000px"
			backgroundImage={MainGreen}
			position="absolute"
			left="25vw"
			top="150px"
			zIndex={0}
		/>
		{/*<MotionBox*/}
		{/*	initial={{ opacity: 0 }}*/}
		{/*	whileInView={{ opacity: 0.4 }}*/}
		{/*	transition={{ duration: 2 }}*/}
		{/*	backgroundImage={WavesMain}*/}
		{/*	backgroundRepeat="no-repeat"*/}
		{/*	backgroundPosition="right"*/}
		{/*	position="absolute"*/}
		{/*	w="100vw"*/}
		{/*	height="800px"*/}
		{/*	top={{ base: "200px", md: "-200px" }}*/}
		{/*/>*/}
	</Box>
);
