import React from "react";
import { Text, Heading, Flex, Box, Container } from "@chakra-ui/react";
import Waves1 from "../../images/waves_home_1.png";
import { teamPoints } from "../../constants";
import { colors } from "../../constants";
import { motion } from "framer-motion";
import MainRed from "../../images/main_red.png";

const MotionBox = motion(Box);

export const Team: React.FC = () => (
	<MotionBox
		mt="240px"
		position="relative"
		initial={{ opacity: 0 }}
		whileInView={{ opacity: 1 }}
		viewport={{ once: true }}
		transition={{ duration: 1 }}
	>
		<Container maxW="container.xl" zIndex={10} position="inherit">
			<Heading color="white" fontSize="40px" textAlign="center" textTransform="uppercase">
				International team
			</Heading>
			<Text
				color="white"
				fontSize="20px"
				textAlign="center"
				px={{ base: "0", md: "25%" }}
				my="40px"
			>
				Our team is distributed in many different countries across Earth's globe. That allows us
                to work with a flexible schedule with clients from Europe and even from the United
                States. Adapt to client timezone, be available any time to chat, and help to solve
                any possible problems.
			</Text>
			<Flex mt="110px" flexDirection={{ base: "column", md: "row" }}>
				{teamPoints.map(elem => (
					<Box key={elem.title} flex={1} mt={{ base: "40px", md: 0 }}>
						<Text
							color="primary"
							textShadow={`0px 0px 11px ${colors.primary}`}
							fontSize="20px"
							fontFamily="Poppins"
							fontWeight="600"
							lineHeight="25px"
							textAlign="center"
						>
							{elem.title}
						</Text>
						<Text color="white" fontSize="16px" textAlign="center" px="10px" mt="20px">
							{elem.description}
						</Text>
					</Box>
				))}
			</Flex>
		</Container>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={MainRed}
			position="absolute"
			top="30px"
			right="15vw"
			zIndex={0}
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			transition={{ duration: 2 }}
			backgroundImage={Waves1}
			backgroundRepeat="no-repeat"
			backgroundPosition="right"
			position="absolute"
			w="100vw"
			height="1300px"
			top={0}
		/>
	</MotionBox>
);
