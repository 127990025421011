import React from "react";
import {
	Box,
	Heading,
	Flex,
	Container,
	useBreakpointValue,
	Button,
	Center,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { colors } from "../../constants";
import { motion } from "framer-motion";
import MainGreen from "../../images/main_green.png";
import { navigate } from "gatsby";

const MotionBox = motion(Box);

export const Partners: React.FC = () => {
	const marginTop = useBreakpointValue({ base: "50px", md: 0 });
	return (
		<Container maxW="container.xl" position="relative">
			<Box position="inherit" zIndex={10}>
				<Heading color="white" fontSize="40px" textAlign="center" textTransform="uppercase">
					Our partners
				</Heading>
				<Box
					border={`2px solid ${colors.pointsBorder}`}
					bg="background"
					borderRadius="50px"
					p="70px"
					mt="70px"
					mb="60px"
				>
					<Flex
						justifyContent="space-between"
						flexDirection={{ base: "column", md: "row" }}
						flexWrap="wrap"
						gap={"20px"}
					>
						<StaticImage
							src="../../images/conic-apps.png"
							alt="Partner Conic Apps"
							objectFit="contain"
						/>
						<StaticImage
							src="../../images/microting-logo.png"
							alt="Partner Microting"
							objectFit="contain"
							style={{ marginTop }}
						/>
						<StaticImage
							src="../../images/artroom.png"
							alt="Partner ArtRoom"
							objectFit="contain"
						/>
						<StaticImage
							src="../../images/email-hippo.png"
							alt="Partner Email Hippo"
							objectFit="contain"
							style={{ marginTop }}
						/>
					</Flex>
				</Box>
				<Center mb={"200px"}>
					<Button
						variant="primary"
						textTransform="uppercase"
						onClick={() => navigate("/contacts")}
					>
						Contact now
					</Button>
				</Center>
			</Box>

			<MotionBox
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				viewport={{ once: true }}
				transition={{ duration: 2 }}
				w="1000px"
				h="1000px"
				backgroundImage={MainGreen}
				position="absolute"
				top="-330px"
				borderRadius="10%"
				left="-300px"
				zIndex={1}
			/>
		</Container>
	);
};
