import React, { useEffect, useRef } from "react";
import ClassicalNoise from "../constants/ClassicalNoise";
import { useBreakpointValue } from "@chakra-ui/react";

interface ICanvasProps {
	canvasHeight?: number;
	canvasWidth?: number;
	amp?: number;
	variation?: number;
	max_lines?: number;
	variator_step?: number;
}

export const Canvas: React.FC<ICanvasProps> = ({
	canvasHeight = 0,
	canvasWidth = 0,
	amp = 300,
	variation = 0.0025,
	max_lines = 25,
	variator_step = 0.02,
}) => {
	const isMobile = useBreakpointValue({ base: true, md: false });
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const canvasCtxRef = React.useRef<CanvasRenderingContext2D | null>(null);

	let start_y = (canvasHeight || 1000) / 2;
	const perlin = new ClassicalNoise();
	const validators: number[] = [];

	useEffect(() => {
		if (canvasRef.current) {
			canvasCtxRef.current = canvasRef.current.getContext("2d");
			const ctx = canvasCtxRef.current;

			for (let i = 0, u = 0; i < max_lines; i++, u += variator_step) {
				validators[i] = u;
			}

			const resizeCanvas = function () {
				canvasRef.current!.setAttribute("width", canvasWidth + "");
				canvasRef.current!.setAttribute("height", canvasHeight + "");

				start_y = canvasHeight / 2;
			};

			resizeCanvas();

			const draw = function () {
				for (let i = 0; i <= max_lines; i++) {
					ctx!.beginPath();
					let y;
					for (let x = 0; x <= canvasWidth; x++) {
						y = perlin.noise(x * variation + validators[i], x * variation, 0);
						ctx!.lineTo(x, start_y + amp * y);
					}
					const gradient = ctx!.createLinearGradient(canvasWidth - 50, 0, 0, 0);

					gradient.addColorStop(0, "transparent");
					gradient.addColorStop(0.05, "rgba(255,255,255, .1)");
					gradient.addColorStop(0.95, "rgba(255,255,255, .1)");
					gradient.addColorStop(1, "transparent");
					ctx!.strokeStyle = isMobile ? "rgba(255,255,255, .1)" : gradient;
					ctx!.stroke();
					ctx!.closePath();

					validators[i] += 0.003;
				}
			};

			const animateX = function () {
				if (canvasRef.current) {
					ctx!.clearRect(0, 0, canvasWidth, canvasHeight);
					draw();
					requestAnimationFrame(animateX);
				}
			};
			requestAnimationFrame(animateX);
		}
	}, [canvasWidth]);

	useEffect(() => {
		return () => {
			if (canvasRef.current) {
				const context = canvasRef.current.getContext("2d");
				context?.clearRect(
					0,
					0,
					canvasWidth ? canvasWidth : 0,
					canvasHeight ? canvasHeight : 0,
				);
				canvasRef.current = null;
			}
			if (canvasCtxRef.current) {
				canvasCtxRef.current?.clearRect(
					0,
					0,
					canvasWidth ? canvasWidth : 0,
					canvasHeight ? canvasHeight : 0,
				);
				canvasCtxRef.current = null;
			}
		};
	}, []);

	return canvasWidth ? (
		<canvas
			ref={canvasRef}
			// style={{ zIndex: 0, position: "absolute", transform: "rotate(-35deg)" }}
		/>
	) : (
		<></>
	);
};
