import React from "react";
import { Box, Heading, Text, Flex, HStack, Container } from "@chakra-ui/react";
import CheckmarkIcon from "../../icons/checkmark.svg";
import Waves2 from "../../images/waves_home_2.png";
import { services } from "../../constants";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

export const Services: React.FC = () => (
	<MotionBox
		mt="130px"
		position="relative"
		initial={{ opacity: 0 }}
		whileInView={{ opacity: 1 }}
		viewport={{ once: true }}
		transition={{ duration: 1 }}
	>
		<Container maxW="container.xl">
			<Heading color="white" fontSize="40px" textAlign="center" textTransform="uppercase">
				Services
			</Heading>
			<Text
				color="white"
				fontSize="20px"
				textAlign="center"
				mt="40px"
				px={{ base: "0", md: "25%" }}
			>
				Our experience is proven through 6+ years of work on the market, and we can
                cover everything the business may need
			</Text>
			<Flex
				justifyContent="space-around"
				mt="70px"
				flexDirection={{ base: "column", md: "row" }}
			>
				{services.map(elem => (
					<Box key={elem.name} mt={{ base: "30px", md: 0 }}>
						<Text
							fontFamily="Poppins"
							fontSize="20px"
							lineHeight="25px"
							color="white"
							mb="40px"
						>
							{elem.name}
						</Text>
						{elem.items.map((item, index) => (
							<HStack key={index} mb="25px">
								<CheckmarkIcon />
								<Text color="white" fontSize="16px">
									{item}
								</Text>
							</HStack>
						))}
					</Box>
				))}
			</Flex>
		</Container>
		<MotionBox
			position="absolute"
			backgroundRepeat="no-repeat"
			backgroundPosition="right"
			top="0"
			width="100%"
			height="1300px"
			backgroundImage={Waves2}
		/>
	</MotionBox>
);
