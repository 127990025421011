import React, { useState } from "react";
import { Box, Heading, Text, HStack, IconButton, Center, Container, Stack } from "@chakra-ui/react";
import ArrowLeftIcon from "../../icons/arrow_left.svg";
import ArrowRightIcon from "../../icons/arrow_right.svg";
import { motion, AnimatePresence } from "framer-motion";
import { colors, projects } from "../../constants";
import MainRed from "../../images/main_red.png";

const MotionBox = motion(Box);

export const Projects: React.FC = () => {
	const [selectedTab, setSelectedTab] = useState(0);

	const nextSlide = () => {
		if (selectedTab === projects.length - 1) {
			setSelectedTab(0);
		} else {
			setSelectedTab(selectedTab + 1);
		}
	};

	const prevSlide = () => {
		if (selectedTab === 0) {
			setSelectedTab(projects.length - 1);
		} else {
			setSelectedTab(selectedTab - 1);
		}
	};

	return (
		<Container maxW="container.xl" position="relative" zIndex={10} my="200px">
			<Heading
				color="white"
				fontSize="40px"
				textAlign="center"
				mb="70px"
				textTransform="uppercase"
			>
				Our projects
			</Heading>
			<Box minHeight={{ base: "550px", md: "350px" }}>
				<AnimatePresence exitBeforeEnter>
					<MotionBox
						key={selectedTab ? selectedTab : "empty"}
						animate={{ opacity: 1, y: 0 }}
						initial={{ opacity: 0, y: 20 }}
						exit={{ opacity: 0, y: -20 }}
						transition={{ duration: 0.5 }}
						zIndex={20}
						position="inherit"
					>
						<Stack
							align="baseline"
							direction={{ base: "column-reverse", md: "row" }}
							spacing={{ base: "30px", md: 0 }}
						>
							<Box flex={1}>
								{/*<Center>{projects[selectedTab].logoOfBusiness}</Center>*/}
								<Heading
									color="white"
									fontSize="30px"
									textAlign="center"
									textTransform="uppercase"
								>
									{projects[selectedTab].projectName}
								</Heading>
								<Text
									color="#8F8F8F"
									textAlign="center"
									fontSize="14px"
									mt="25px"
									mb="40px"
								>
									{projects[selectedTab].businessDescription}
								</Text>
								<Text color="white" textAlign="center" px="10%">
									{projects[selectedTab].text}
								</Text>
								{/*<Center>*/}
								{/*	<Button variant="primary" mt="50px" textTransform="uppercase">*/}
								{/*		View more*/}
								{/*	</Button>*/}
								{/*</Center>*/}
							</Box>
							<Box flex={1}>{projects[selectedTab].projectScreenshot}</Box>
						</Stack>
					</MotionBox>
				</AnimatePresence>
			</Box>
			<Center mt={{ base: "30px", md: "70px" }} zIndex={10} position="inherit">
				<HStack spacing="30px">
					<IconButton
						aria-label="Slider left"
						variant="unstyled"
						onClick={prevSlide}
						_focus={{ boxShadow: "none" }}
					>
						<ArrowLeftIcon />
					</IconButton>
					<IconButton
						aria-label="Slider right"
						variant="unstyled"
						onClick={nextSlide}
						_focus={{ boxShadow: "none" }}
					>
						<ArrowRightIcon />
					</IconButton>
				</HStack>
			</Center>
			<MotionBox
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				viewport={{ once: true }}
				transition={{ duration: 2 }}
				w="1000px"
				h="1000px"
				backgroundImage={MainRed}
				position="absolute"
				top="-100px"
				borderRadius="50%"
				right="-200px"
				zIndex={-1}
			/>
		</Container>
	);
};
